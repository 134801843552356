@import './tailwind';
@import './general';
@import './config';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

:root {
    .swiper-pagination-bullet {
        @apply opacity-100 bg-white border-2 border-white #{!important};
    }

    .swiper-pagination-bullet-active {
        @apply bg-primary #{!important};
    }
}

body {
    @apply font-body;
}

strong {
    font-weight: 500;
}
