.pagination-base {
    @apply flex items-center justify-between p-1;

    .pagination-btn {
        @apply disabled:pointer-events-none disabled:opacity-25;
    }

    .pages-container {
        @apply flex gap-4 font-semibold h-8 items-center;

        .page-btn {
            @apply w-10 h-10 btn-pagination btn;

            &-active {
                @apply btn-primary;
            }
        }
    }
}

@layer components {
    .pagination {
        @apply pagination-base;
    }
}
