h4 {
    @apply text-h4 text-heading;
}

h3 {
    @apply text-h3 text-heading;
}

h2 {
    @apply text-h2 text-heading;
}

h1 {
    @apply text-h1 text-heading;
}

button {
    touch-action: manipulation;
}
