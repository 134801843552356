@config "./tailwind.config.ts";

@font-face {
    font-family: 'Century Gothic';
    src: url('./fonts/century-gothic/gothic-regular.ttf');
}

@font-face {
    font-family: 'Century Gothic';
    src: url('./fonts/century-gothic/gothic-bold.ttf');
    font-weight: 700;
}
